// The following flags will work for all browsers.
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick

// ** Not work with ngx-datatable's scrollbarV enabled (uncomment if used)
(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame

// ** Not work with ngx-datatable's scrollbarV enabled (remove 'scroll' if used)
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
// (window as any).__zone_symbol__UNPATCHED_EVENTS = ['mousemove']; // disable patch specified eventNames

// in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
// with the following flag, it will bypass `zone.js` patch for IE/Edge
(window as any).__Zone_enable_cross_context_check = true;
